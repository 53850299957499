<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>收款明细表</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>

    <div style="padding: 20px 20px 0; white-space: nowrap">
      <el-row>
        <el-col :span="6">
          <span>日期范围：</span>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="10">
          <el-input
            v-model="QueryContent"
            placeholder="请输入付款方式、姓名或手机号"
            style="width: 70%"
          ></el-input>
          <el-button type="success" size="mini" style="margin-left: 5px">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">查询</span>
            </div>
          </el-button>
          <el-button type="primary" size="mini" style="margin-left: 5px">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">导出报表</span>
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div style="padding: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="creationTime"
          sortable
          label="创建时间"
        ></el-table-column>
        <el-table-column
          prop="abstract"
          label="摘要"
        ></el-table-column>
        <el-table-column prop="payment" label="付款方式"></el-table-column>
        <el-table-column prop="payType" label="付款类型"></el-table-column>
        <el-table-column prop="payer" label="付款人"></el-table-column>
        <el-table-column prop="phone" sortable label="号码"></el-table-column>
        <el-table-column prop="money" sortable label="金额"></el-table-column>
        <el-table-column prop="type" label="类型"></el-table-column>
      </el-table>
      <div class="tableBottom">
        <span v-if="isResult" style=""
          >显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >
        <span v-if="!isResult" style=""
          >显示&nbsp;{{ tableFinshNum }}&nbsp;项结果，共&nbsp;{{
            tableFinshNum
          }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      date: "",

      QueryContent: "",

      tableData: [],
      isResult: false,
      tableFinshNum: 0,
    };
  },
  mounted() {
    if (this.tableData.length == 0) {
      this.isResult = false;
    } else {
      this.isResult = true;
      this.tableFinshNum = this.tableData.length;
    }
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>
<style scoped lang="less">
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}
/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
